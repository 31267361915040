import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { GlobalStyles } from '../base/styles/global-styles'

import ExceptionApp from '../base/ExceptionApp'

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <BrowserRouter>
      <GlobalStyles />
      <ExceptionApp />
    </BrowserRouter>,
    document.body.appendChild(document.createElement('div')),
  )
})
