import './translations'

import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { InternalServerError, PageNotFound } from './pages'

const status: number = parseInt(
  document.getElementById('status')?.getAttribute('code') || '404',
)

class ExceptionApp extends React.Component {
  render() {
    return (
      <Switch>
        <Route component={status < 500 ? PageNotFound : InternalServerError} />
      </Switch>
    )
  }
}

export default ExceptionApp
